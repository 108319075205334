import React, { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";


const AppChat = lazy(()=> import("../AppChat"));
const ComingSoon = lazy(()=> import("../ComingSoon"));
const App404 = lazy(()=> import("../App404"));

const RoutingComponent = props => {

const linkType = window.location.pathname.split('/')[1]; 
sessionStorage.setItem("link-type", linkType);
sessionStorage.setItem("country","india")
sessionStorage.setItem("skuId", null);

  return (
    <Suspense fallback={<div></div>}>
    <Router>
        <Switch>
        <Route path="/ml-chat/:id" render ={
                    ()=>{
                    if(navigator.userAgent.indexOf('gomec-retail-android') != -1){
                        sessionStorage.setItem("retailapp", true);
                    }else{
                        sessionStorage.setItem("retailapp", false);
                    }
                    // alert(localStorage.getItem("retail"));
                    sessionStorage.setItem("chatType", 'retail');
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^[0-9]{8,12}$/;
                    if(urlNumber.match(phoneno)){
                        if(window.location.search){
                            const params = new URLSearchParams(window.location.search); 
                            const order_id = params.get('order_id');
                            sessionStorage.setItem("orderId", order_id);
                        }
                        sessionStorage.setItem("country",'malaysia')
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                }
            }/>
            <Route path="/chat/:id" render ={
                    ()=>{
                    if(navigator.userAgent.indexOf('gomec-retail-android') != -1){
                        sessionStorage.setItem("retailapp", true);
                    }else{
                        sessionStorage.setItem("retailapp", false);
                    }
                    // alert(localStorage.getItem("retail"));
                    sessionStorage.setItem("chatType", 'retail');
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^\d{10}$/;
                    if(urlNumber.match(phoneno)){
                        if(window.location.search){
                            const params = new URLSearchParams(window.location.search); 
                            const order_id = params.get('order_id');
                            const sku_id = params.get('sku_id');
                            sessionStorage.setItem("orderId", order_id);
                            sessionStorage.setItem("skuId", sku_id);
                        }
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                }
            }/>
            <Route path="/spares-chat/:id" render ={
                    ()=>{
                    if(navigator.userAgent.indexOf('gomec-retail-android') != -1){
                        sessionStorage.setItem("retailapp", true);
                    }else{
                        sessionStorage.setItem("retailapp", false);
                    }
                    // alert(localStorage.getItem("retail"));
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^\d{10}$/;
                    if(urlNumber.match(phoneno)){
                        if(window.location.search){
                            const params = new URLSearchParams(window.location.search); 
                            const order_id = params.get('order_id');
                            sessionStorage.setItem("orderId", order_id);
                            const item_id = params.get('item_id');
                            sessionStorage.setItem("itemId", item_id);
                        }
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("chatType", 'spares');
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                    // return <ComingSoon />
                }
            }/>
             <Route path="/obd-chat/:id" render ={
                    ()=>{
                    if(navigator.userAgent.indexOf('gomec-retail-obd') != -1){
                        sessionStorage.setItem("retailapp", true);
                    }else{
                        sessionStorage.setItem("retailapp", false);
                    }
                    sessionStorage.setItem("chatType", 'retail');
                    // alert(localStorage.getItem("retail"));
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^\d{10}$/;
                    if(urlNumber.match(phoneno)){
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                }
            }/>
            <Route path="/custom-chat/:id" render ={
                    ()=>{
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^\d{10}$/;
                    sessionStorage.setItem("chatType", 'retail');
                    if(window.location.search){
                        const params = new URLSearchParams(window.location.search); 
                        const service_id = params.get('service_id');
                        sessionStorage.setItem("serviceId", service_id);
                    }
                    if(urlNumber.match(phoneno)){
                        sessionStorage.setItem("customChat", true);
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                }
            }/>
             <Route path="/ml-custom-chat/:id" render ={
                    ()=>{
                    var urlNumber = window.location.pathname.split('/')[2]; 
                    var phoneno = /^[0-9]{8,12}$/;
                    sessionStorage.setItem("chatType", 'retail');
                    if(window.location.search){
                        const params = new URLSearchParams(window.location.search); 
                        const service_id = params.get('service_id');
                        sessionStorage.setItem("serviceId", service_id);
                    }
                    if(urlNumber.match(phoneno)){
                        sessionStorage.setItem("customChat", true);
                        sessionStorage.setItem("country",'malaysia')
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat phoneno={urlNumber}/>
                    } else {
                        return <App404 />
                    }  
                }
            }/>
            <Route path="/chat" render ={
                    ()=>{
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("confirmBooking", false);
                        sessionStorage.setItem("chatType", 'retail');
                        return <AppChat />
                        }
            }/>
            <Route path="/obd-chat" render ={
                    ()=>{
                        sessionStorage.setItem("customChat", false);
                        sessionStorage.setItem("serviceId", null);
                        sessionStorage.setItem("chatType", 'retail');
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat />
                        }
            }/>
            <Route path="/custom-chat" render ={
                    ()=>{
                        if(window.location.search){
                            const params = new URLSearchParams(window.location.search); 
                            const service_id = params.get('service_id');
                            sessionStorage.setItem("serviceId", service_id);
                        }
                        sessionStorage.setItem("customChat", true);
                        sessionStorage.setItem("chatType", 'retail');
                        sessionStorage.setItem("confirmBooking", false);
                        return <AppChat />
                        }
            }/>
            <Route path="/confirm-booking/:id" render ={
                    ()=>{
                        var urlNumber = window.location.pathname.split('/')[2]; 
                        var phoneno = /^\d{10}$/;
                        sessionStorage.setItem("chatType", 'retail');
                        if(window.location.search){
                            const params = new URLSearchParams(window.location.search); 
                            const order_id = params.get('order_id');
                            sessionStorage.setItem("orderId", order_id);
                        }
                        if(urlNumber.match(phoneno)){
                            sessionStorage.setItem("customChat", false);
                            sessionStorage.setItem("serviceId", null);
                            sessionStorage.setItem("confirmBooking", true);
                            return <AppChat phoneno={urlNumber}/>
                        } else {
                            return <App404 />
                        }  
                    }
            }/>
            <Route component={App404}/>
        </Switch>
    </Router>
    </Suspense>
  );
};

export default RoutingComponent;
