import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
// import './index.css';
import './hurme1.css';
// import * as serviceWorker from './serviceWorker';
// import firebase from 'firebase'

// const linkType = window.location.pathname.split('/')[1]; 
// sessionStorage.setItem("link-type", linkType);
// sessionStorage.setItem("country","india")

ReactDOM.render(<App />,document.getElementById('page-body'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// const express = require('express');
// const path = require('path');

// const app = express();

// // Serve the static files from the React app
// app.use(express.static(path.join(__dirname, 'client/build')));

// // An api endpoint that returns a short list of items
// // app.get('/api/getList', (req,res) => {
// //     var list = ["item1", "item2", "item3"];
// //     res.json(list);
// //     console.log('Sent list of items');
// // });

// // Handles any requests that don't match the ones above
// app.get('*', (req,res) =>{
//     res.sendFile(path.join(__dirname+'/client/build/index.html'));
// });

// const port = process.env.PORT || 5000;
// app.listen(port);

// console.log('App is listening on port ' + port);