import React from "react";
import RoutingComponent from './routes/routes'

function App() {
  return (
      <RoutingComponent />
  );
}

export default App;
